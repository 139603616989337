import React, { useEffect, useRef } from "react";

export const NumericInput = ({ ...props }: any) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <input
      ref={inputRef}
      type="tel"
      pattern="[0-9]*"
      autoFocus
      {...props}
      inputMode="numeric"
    />
  );
};
