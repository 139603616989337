import React, { useState, useEffect, useContext } from "react";
import { Header } from "../../components/Header";
import { MainContainer } from "./styles";
import ReactLoading from "react-loading";
import "../../index.css";
import { api } from "../../services/api";
import Docks from "./Components/Docks";
import Orders from "./Components/Orders";
import Register from "./Components/Register";
import { AuthContext } from "../../Shared/AuthContext";

export const Home = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[] | undefined>();
  const [dataOrders, setDataOrders] = useState<any[] | undefined>();
  const [selectedDock, setSelectedDock] = useState<any>();
  const [selectedOrder, setSelectedOrder] = useState();
  // const [selectedCompany, setSelectedCompany] = useState();
  const [products, setProducts] = useState([]);
  const [dataLoad, setDataLoad] = useState();
  const [dataLoadItem, setDataLoadItem] = useState();

  const { authenticateUser } = useContext(AuthContext);
  const CODUSU = authenticateUser?.idUsuario;

  const getData = async () => {
    setLoading(true);

    const response = await api.get(`load/list/docks?CODUSU=${CODUSU}`);
    console.log(response);

    if (response?.data) {
      setData(response?.data);
    }
    setLoading(false);
  };
  const getDataOrders = async () => {
    setLoading(true);

    const response = await api.get(
      `load/list/orders?CODDOCA=${selectedDock?.CODDOCA}`
    );

    if (response?.data) {
      setDataOrders(response?.data);
    }
    setLoading(false);
  };
  const getDataProducts = async () => {
    setLoading(true);

    const response = await api.get(
      `load/list/products?CODDOCA=${selectedDock?.CODDOCA}&ORDEMCARGA=${selectedOrder}`
    );

    if (response?.data) {
      setProducts(response?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedDock) getDataOrders();
  }, [selectedDock]);

  useEffect(() => {
    if (selectedOrder) getDataProducts();
  }, [selectedOrder]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Header label={`Carregamento`} />
      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactLoading
            type={"cylon"}
            color={"#ff8000"}
            height={"150px"}
            width={"150px"}
          />
        </div>
      )}
      {!loading && (
        <MainContainer>
          {/* {!selectedCompany && <Companies setSelected={setSelectedCompany} />} */}

          {!selectedDock && (
            <Docks
              data={data}
              setSelected={setSelectedDock}
              setSelectedCompany={() => {}}
            />
          )}
          {selectedDock && !selectedOrder && (
            <Orders
              data={dataOrders}
              setSelected={setSelectedOrder}
              setSelectedDock={setSelectedDock}
              selectedDock={selectedDock}
              dataLoad={dataLoad}
              dataItemLoad={dataLoadItem}
              setDataLoad={setDataLoad}
              setDataLoadItem={setDataLoadItem}
              selectedCompany={null}
            />
          )}
          {selectedDock && selectedOrder && (
            <Register
              setSelectedDock={setSelectedDock}
              setSelectedOrder={setSelectedOrder}
              selectedDock={selectedDock}
              selectedOrder={selectedOrder}
              products={products}
              dataLoad={dataLoad}
              selectedCompany={null}
              setDataLoadItem={setDataLoadItem}
              dataLoadItem={dataLoadItem}
            />
          )}
        </MainContainer>
      )}
    </div>
  );
};
